import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lepiota inconfusible per la morfologia del seu capell amb nombroses i fortes esquames en forma d’espines que es desprenen al tacte. El capell, de 5 a 12 cm de diàmetre primerament globós i estès amb l’edat i de color marró terrós a rogenc. Les làmines són blanques, lliures i amb l’aresta típicament bifurcada. El peu és curt i gruixut, de 4 a 15 x 0,5 a 1,5 cm, cilíndric i més ample a la base, de color semblant al capell o més clar. Té un anell fràgil amb restes d’espines piramidals. Les espores són blanquinoses en massa, el·lipsoïdals, de 6-10 x 3,5-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      